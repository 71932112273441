<template>
  <div v-show="showVideo" class="videoContainer" v-touch:swipe="onTap">
    <div @click="exit()" class="close">
      <simpleClose />
    </div>
    <transition
        enterActiveClass="animated fadeIn"
        leaveActiveClass="animated fadeOut"
    >
      <div class="Options" v-show="showOptions">
        <div class="Options__option" v-for="option in fOptions" :key="option.video_id">
          <div
              class="Options__option__cover"
              :style="{ backgroundImage: `url(${option.cover.replace('{$width}', '324')})`}"
              @click="switchVideo(option)"
          >
          </div>
          <div class="Options__option__title">
            {{ option.title }}
          </div>
        </div>
      </div>
    </transition>
    <video id="RDS-360Player" class="video-js video-js-vr vjs-default-skin" controls="" playsinline="" width="640" height="300">
    </video>
  </div>
</template>

<script>

import simpleClose from '@/components/glyphs/SimpleClose.vue';
import {
  mapMutations,
  mapState,
} from 'vuex';
import { filter } from 'lodash';

export default {
  props: ['url', 'token', 'options', 'showOptions', 'id', 'type', 'leftFix'],
  name: 'Player360',
  components: {
    simpleClose,
  },
  data() {
    return {
      showVideo: false,
      player360: false,
      rbv: true,
      interval: false,
      checkExist: false,
    };
  },
  mounted() {
    this.play(this.url);
  },
  methods: {
    ...mapMutations([
      'setRdsPlayer',
    ]),
    onTap() {
      this.$emit('tapped');
    },
    switchVideo(payload) {
      this.exit();
      this.$emit('handleVideo', payload);
    },
    exit() {
      this.$fullscreen.exit();
      this.showVideo = false;
      this.player360.pause();
      this.player360.dispose();
      this.$emit('exit');
    },
    play(url) {
      // eslint-disable-next-line no-multi-assign
      const player360 = window.player360 = window.videojs('RDS-360Player', {
        inactivityTimeout: 5000,
        controlBar: {
          fullscreenToggle: false,
        },
      });
      this.$emit('setPlayer', player360);
      this.player360 = player360;
      if (['live', 'live_360'].includes(this.type)) this.player360.controls(false);
      this.player360.mediainfo = this.player360.mediainfo || {};
      // player.mediainfo.projection = '360_CUBE';
      // this.player360.mediainfo.projection = '360';
      // this.player360.mediainfo.projection = 'Sphere';
      this.player360.mediainfo.projection = '360';
      // AUTO is the default and looks at mediainfo
      window.vr = this.player360.vr({
        projection: 'AUTO',
        // debug: true,
        // forceCardboard: false,
      });
      this.player360.src({
        type: 'application/x-mpegURL',
        src: url,
      });
      this.setRdsPlayer(this.player360);
      this.player360.play();
      this.showVideo = true;
      if (this.leftFix) {
        this.checkExist = setInterval(() => {
          const elA = document.querySelectorAll('#RDS-360Player > canvas');
          if (elA.length) {
            elA[0].style.left = 0;
            clearInterval(this.checkExist);
          }
        }, 100);
      }
    },
  },
  computed: {
    ...mapState([
      'reactionStripeConfig',
      'rdsPlayer',
      'rdsPlayer360',
      'rdsPlayerShow',
      'rdsPlayer360Show',
      'conf',
    ]),
    fOptions() {
      const filtered = filter(this.options, o => o.video_id !== this.id);
      return filtered;
    },
  },
};
</script>

<style lang="scss">
.videoContainer {
  position: relative;
}
.close {
  width: 50px;
  height: 50px;
  position: absolute;
  z-index: 5;
  top: 20px;
  left: 20px;
}
.video-js > canvas {
  left: 0;
}
.video-js {
  &.vjs-paused{
    .vjs-big-play-button {
      display: block;
    }
  }
}
</style>
